// Setup: npm install @alch/alchemy-sdk
// Github: https://github.com/alchemyplatform/alchemy-sdk-js
import { Network, initializeAlchemy, getNftsForOwner } from "@alch/alchemy-sdk";

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
  apiKey: "_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J", // Replace with your Alchemy API Key.
  network: Network.ETH_MAINNET, // Replace with your network.
  maxRetries: 10,
};

const alchemy = initializeAlchemy(settings);


  export const getOwnedMBH = async(walletAddress) => {


    var forEachData = ''
    var NFTArray = [];


    // // Print total NFT count returned in the response:
    // const nftsForOwner = await getNftsForOwner(
    //     alchemy, 
    //     "0x1BBc00c2b519784b543B20684165351452B99B5c",
    //     {
    //         contractAddresses: ["0x82592bc6dF83f4491c6719840190a8F65bf9aAE7"],
    //     }
    //     );

        
    // console.log(nftsForOwner);


        async function callGetNFTsForCollectionOnce(
          startToken = ""
        ) {
          var nftsForOwner = await getNftsForOwner(
            alchemy, 
            walletAddress,
            {
                contractAddresses: [
                    "0x982cd174651725c41292Aa0F751445Ae746266Ea", // Main Collection
                    "0x5cFf6C21FE40663892927b6858091a4795D0e5C9", // Private Collection
                    "0x82592bc6dF83f4491c6719840190a8F65bf9aAE7" // Apes
                  ],
                // pageKey: startToken,
            }
            );
          return nftsForOwner;
        }




        let startToken = "";
        let hasNextPage = true;
        let totalNftsFound = 0;
        while (hasNextPage) {
          const { ownedNfts, pageKey } = await callGetNFTsForCollectionOnce(
            startToken
          );
          // if (!pageKey) {
            // When nextToken is not present, then there are no more NFTs to fetch.
            hasNextPage = false;
          // }
          startToken = pageKey;
          totalNftsFound += ownedNfts.length;
          NFTArray = NFTArray.concat(ownedNfts);
        }
      
        NFTArray = NFTArray.reverse();


        // for(var i = 0, NFTArray.length, i++) {
        //   console.log(ownedNfts[i].rawMetadata.attributes[0]);
        // }
        


        let collectorScore = 0;

        NFTArray.forEach(
          async function(d){

              // console.log(d.rawMetadata.attributes[0]);


              
              if(d.rawMetadata.attributes[0].trait_type == "Collector Score") {
                collectorScore = collectorScore + Number(d.rawMetadata.attributes[0].value);
              } else if(d.rawMetadata.attributes[1].trait_type == "Collector Score") {
                collectorScore = collectorScore + Number(d.rawMetadata.attributes[1].value);
              }

              
              if(d.rawMetadata.attributes[0].trait_type == "Background") {
                collectorScore++;
              }

              forEachData += '<div class="col-md-4 pb-4"><div class="card h-100"><div class="card-body"><h5 class="card-title">' + d.title + '</h5><p class="card-text"><img src="' + d.rawMetadata.image + '" class="img-fluid" /></p></div></div></div>';

           }
        )

        return {
          collected: forEachData,
          collectorScore: collectorScore,
        }
  }
