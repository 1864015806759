// import fetch from 'node-fetch';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);


const membershipABI = require('../abi/membership-abi.json')
const membershipAddress = "0xb72F22CC443D3cedD637e9a97B8727d358371c6a";


const coinABI = require('../abi/coin-abi.json')
const coinAddress = "0x3E375D9d3b9A9FC80D9e8ab39B5a2A72BF29391E";


// const mainABI = require('../main-abi.json')
// const mainContract = "";

// const passesABI = require('../abi/passes-abi.json')
// const passesAddress = "0x6C4Df0D52175690efdb04f587bAbEF73c187d89E";

const apeABI = require('../abi/ape-abi.json')
const apeAddress = "0x82592bc6dF83f4491c6719840190a8F65bf9aAE7";


const MySwal = withReactContent(Swal)


export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  
  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the button above.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const mintMembershipNFT = async() => {

    window.membershipContract = await new web3.eth.Contract(membershipABI, membershipAddress);//loadContract();

    window.apeContract = await new web3.eth.Contract(apeABI, apeAddress);//loadContract();



    var amount = 1;

    var tokenPrice = await window.membershipContract.methods.tokenPrice().call();
  
    var tokenDiscount = await window.membershipContract.methods.tokenDiscount().call();


    var apes = await window.apeContract.methods.balanceOf(window.ethereum.selectedAddress).call();

    if(apes > 0) {
      tokenPrice = Number(tokenPrice) - Number(tokenDiscount);
    }

    if(apes >= 10) {
      tokenPrice = Number(tokenPrice) - Number(tokenDiscount);
    }


    var total = amount * tokenPrice;



    // console.log(tokenPrice);
    // console.log(amount);
    // console.log(total);

    // REDEPLOY MEMBERSHIP CONTRACT TO TEST PRICING BUG
    // total = total / 3;






    if (await window.membershipContract.methods.paused().call()) {
      MySwal.fire({
        title: <p>Sorry</p>,
        text: 'Minting is paused.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return {
          success: false,
          status: "❗Minting is paused.",
      }
    }

    var totalSupply = await window.membershipContract.methods.totalSupply(1).call();
    var tokenQty = await window.membershipContract.methods.tokenQty().call();

    if (Number(totalSupply) >= Number(tokenQty)) {
      MySwal.fire({
        title: <p>Sorry</p>,
        text: 'All Minted.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return {
          success: false,
          status: "❗All Minted.",
      }
    }

    var maxMintQty = await window.membershipContract.methods.maxMintQty().call();

    if (Number(amount) > Number(maxMintQty)) {
      MySwal.fire({
        title: <p>Sorry</p>,
        text: 'Mint quantity is too high.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return {
          success: false,
          status: "❗Mint quantity is too high.",
      }
    }

    const transactionParameters = {
      to: membershipAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, 
      value: web3.utils.numberToHex(total),
      'gas': '30000',
      // 'maxFeePerGas': '1000000108',
      'data': window.membershipContract.methods.mint(amount).encodeABI() //make call to NFT smart contract 
    };

    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
}

export const getBalances = async() => {

  window.membershipContract = await new web3.eth.Contract(membershipABI, membershipAddress);//loadContract();
  window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();
  window.apeContract = await new web3.eth.Contract(apeABI, apeAddress);//loadContract();


    var membershipsOwned = await window.membershipContract.methods.getMembershipTokenCount(window.ethereum.selectedAddress).call();
    var genesisOwned = await window.membershipContract.methods.balanceOf(window.ethereum.selectedAddress, 1).call();
    var apesOwned = await window.apeContract.methods.balanceOf(window.ethereum.selectedAddress).call();
    var totalSupply1 = await window.membershipContract.methods.totalSupply(1).call();
    var remainingSupply1 = Number(50) - Number(totalSupply1);

    var coinsOwned = await window.coinContract.methods.balanceOf(window.ethereum.selectedAddress).call();

    if(!coinsOwned) {
      coinsOwned = 0;
    } else {
      // coinsOwned = (coinsOwned / Math.pow(10, 18)).toLocaleString();
      coinsOwned = (coinsOwned / Math.pow(10, 18));
    }

    if(membershipsOwned > 0) {
      var coinsUnclaimed = await window.coinContract.methods.getUnclaimedMalibuCoins(window.ethereum.selectedAddress).call();

      if(!coinsUnclaimed) {
        coinsUnclaimed = 0;
      } else {
        // coinsUnclaimed = 34523453345000000000000000
        // coinsUnclaimed = (coinsUnclaimed / Math.pow(10, 18)).toLocaleString();
        coinsUnclaimed = (coinsUnclaimed / Math.pow(10, 18));
      }
    } else {
      coinsUnclaimed = 0;
    }


    // var tokenPrice = await window.membershipContract.methods.tokenPrice().call();
    // var tokenDiscount = await window.membershipContract.methods.tokenDiscount().call();


    var tokenPrice = 0.15;

    if(apesOwned > 0) {
      tokenPrice = 0.10;
      // tokenPrice = (tokenPrice) - (tokenDiscount);
    }

    if(apesOwned >= 10) {
      tokenPrice = 0.05;
      // tokenPrice = (tokenPrice) - (tokenDiscount);
    }

  //   tokenPrice = web3.utils.toWei(
  //     web3.utils.toBN(Number(tokenPrice)), // converts Number to BN, which is accepted by `toWei()`
  //     'ether'
  // );

    
  // console.log(coinsUnclaimed);



    return {
      coinsOwned: coinsOwned,
      coinsUnclaimed: coinsUnclaimed, 
      membershipsOwned: membershipsOwned, 
      genesisOwned: genesisOwned,
      remainingSupply1: remainingSupply1,
      tokenPrice: tokenPrice,
    }
}

export const claimCoins = async() => {

  window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();





  // require(maxSupply > totalSupply(), "All tokens issued");

  // require(isOwner, "You need a membership token to claim");




  const transactionParameters = {
    to: coinAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, 
    'data': window.coinContract.methods.claimMalibuCoins().encodeABI() //make call to NFT smart contract 
  };

  try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
      }
  } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  }
}
