import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Minter from './Minter'
import MintCommissions from "./MintCommissions";
import UserCollection from "./UserCollection";
import MBHCollection from "./MBHCollection";

const Pages = () => (
  <Router>
    <div class="navbar-dark text-white">
      <div class="container">
        <nav class="navbar px-0 navbar-expand-lg navbar-dark">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav pt-4">
              <Link to="/" className="px-4 text-decoration-none text-light lead text-mono">Malibu Beach Hut</Link>
              <Link to="/membership" className="px-4 text-decoration-none text-light lead text-mono">Membership</Link>
              <Link to="/commissions" className="px-4 text-decoration-none text-light lead text-mono">Commissions</Link>
              <Link to="/user" className="px-4 text-decoration-none text-light lead text-mono">Your Beach Hut</Link>
            </div>
          </div>
        </nav>

        <Routes>
            <Route path="/" element={<MBHCollection/>} exact />
            <Route path="/membership" element={<Minter/>} exact />
            <Route path="/commissions" element={<MintCommissions/>} exact />
            <Route path="/user" element={<UserCollection/>} exact />
        </Routes>
  
      </div>
    </div>

  </Router>
);

export default Pages;
