import { connectWallet, getCurrentWalletConnected, getBalances } from "./utils/interact.js";
import {  beachHutCommissions, collectQuantities, mintNFT, mintUnlockable } from "./utils/main-collection.js";
import { useEffect, useState } from "react";
import { ClipLoader, ClockLoader } from 'react-spinners';

const MintCommissions = (props) => {
  // State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [book, setBook] = useState("");

  const [coinsOwned, setCoinsOwned] = useState("");

  const [unlockable, setUnlockable] = useState("");
  const [tokenQuantities, setTokenQuantities] = useState([]);

  const [approved, setApproved] = useState("");
  const [unlockableNFT, setUnlockableNFT] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    loadData();
  };

  const onMintPressed = async () => {
    await mintNFT(book, 1);
  };

  const onUnlockPressed = async () => {
    await mintUnlockable();
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👇 Select the book you'd like to mint below, choose the quantity and Mint your Book(s).");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  };

  const loadData = async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);

    addWalletListener();

    if (address) {
      const { coinsOwned } = await getBalances();
      setCoinsOwned(coinsOwned);

      const { unlockable, tokenQuantities } = await collectQuantities();
      setUnlockable(unlockable);
      setTokenQuantities(tokenQuantities);

      const { approved, unlockableNFT } = await beachHutCommissions();
      setApproved(approved);
      setUnlockableNFT(unlockableNFT);
    }
  };

  return (
    <div className="MainCommissions">
      <div class="jumbotron bg-transparent mb-0 radius-0">
            <div class="container">
                <div class="row">
                      <div class="col-xl-12">
                          <h1 class="display-2">Malibu Beach Hut 3.0</h1>
                          <div class="lead mb-3 text-mono text-success">Mint commission pieces &amp; claim your Unlockable NFTs<span class="vim-caret">_</span></div>
                      </div>
                </div>
            </div>
            <div class="container py-5 mb5">
                <div class="row pt-4 bg-success rounded">

                  <div class="col-6">
                      <div class="text-mono mb-4">
                      {/* {walletAddress.length > 0 && */}

                          <a href="#!"
                            class="btn btn-danger btn-shadow px-3 my-2 ml-0 ml-sm-1 text-left" id="walletButton" onClick={connectWalletPressed}>
                            {walletAddress.length > 0 ? (
                              "Connected: " +
                              String(walletAddress).substring(0, 6) +
                              "..." +
                              String(walletAddress).substring(38)
                            ) : (
                              <span>Connect Wallet</span>
                            )}
                          </a>
                      {/* }   */}
                      </div>
                  </div>

                  <div class="col-6 text-right pt-3">
                        {walletAddress.length > 0 &&            
                            <h4 class="mb-3">
                                <span class="text-white pr-3">Your Malibu Coins</span>
                                <span class="badge badge-dark badge-pill">{coinsOwned}</span>
                            </h4>
                        }
                  </div>




                    
                  </div>

                  {/* {walletAddress.length > 0 &&
                      <div class="row gx-4 gx-lg-5 pt-4">
                            <div class="col-md-8 mb-5 mx-auto">
                                <div class="card h-100">
                                    <div class="card-body d-flex align-items-end">
                                        <p class="card-text">
                                          <img src="images/KAREEM.png" class="img-fluid" />
                                          <br /><div class="bg-primary text-white py-2">&nbsp;&nbsp;&nbsp;{unlockable} remaining</div>
                                        </p>
                                    </div>
                                    <div class="card-footer text-mono pt-3">
                                      {walletAddress.length > 0 && (
                                        <>
                                        {Number(unlockable) == Number(0) ? (
                                          <a  href="#!"
                                              class="btn btn-danger btn-shadow px-3 my-2 ml-0 text-left">
                                              SOLD OUT
                                          </a>
                                        ) : (
                                          <>
                                            {!unlockableNFT ? (
                                              <a  href="#!"
                                                  class="btn btn-danger btn-shadow px-3 my-2 ml-0 text-left">
                                                  You do not own enough base NFTs for this unlockable.
                                              </a>
                                            ) : (

                                              <a  href="#!"
                                                  class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left" id="mintUnlockable" onClick={onUnlockPressed}>
                                                  Mint Unlockable NFT
                                              </a>

                                            )}
                                          </>
                                        )}
                                        </>
                                      )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}


{walletAddress.length > 0 ? 
  tokenQuantities.length > 0 ? (
      <form class="form">
        <div onChange={(event) => setBook(event.target.value)}>
          <div class="row gx-4 gx-lg-5 pt-5">
            {tokenQuantities.map((token, index) => (
              <div class="col-md-4 mb-5">
                <div class="card h-100">
                  <div class="card-body d-flex align-items-end">
                    <p class="card-text">
                      <img src={token.image} class="img-fluid" />
                      <br />
                      <div class="bg-primary text-white py-2">
                        &nbsp;&nbsp;&nbsp;{token.quantity} Remaining <span class="float-right pr-2"> &nbsp;300 KMC</span>
                      </div>
                    </p>
                  </div>

                  <div class="card-footer inputGroup">
                    <input id={`radio${index}`} value={token.id} name="book" type="radio" />
                    <label for={`radio${index}`}>{token.name}</label>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
    </form>
  ) : (
    <div className="d-flex justify-content-center mt-5">
      <ClockLoader color="#17b06b" />
    </div>
  ) : null
}




          <div class="row py-4">

            <div class="col-6 mx-auto  text-center">

            {tokenQuantities.length > 0 &&

                  <div class="form-group text-mono">

                    <button id="mintButton" onClick={onMintPressed} class="btn btn-success btn-shadow px-3 my-2 ml-0 ml-sm-1 text-left">
                      {approved}
                    </button>

                  </div>

            }

            </div>

          </div>
        </div>
    </div>
</div>



  );
};

export default MintCommissions;
