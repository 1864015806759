import parse from 'html-react-parser'
import { getCollection } from "./utils/alchemy-collection-mbh.js";
import { useEffect, useState } from "react";

const MBHCollection = (props) => {

  //State variables
  const [NFTCollection, setNFTCollection] = useState("");

  useEffect(async () => { //TODO: implement
    const { mainCollection } = await getCollection();
    setNFTCollection(mainCollection);
  }, []);

  return (
      <div className="Commissions">
            <div class="jumbotron bg-transparent mb-0 radius-0">
                  <div class="container">
                      <div class="row">
                            <div class="col-xl-12">
                                <h1 class="display-2">Malibu Beach Hut 3.0</h1>
                                <div class="lead mb-3 text-mono text-success">Our main NFT Collection<span class="vim-caret">_</span></div>
                            </div>
                      </div>
                  </div>
                  <div class="container py-5 mb5">
                      <div class="row py-1">

                         { parse(NFTCollection) }

                        </div>
                  </div>
            </div>
      </div>
  );
};

export default MBHCollection;
