import './App.css';

import Pages from './Pages';

function App() {
  return (
    <div className="App">
      <Pages></Pages>
    </div>
  );
}

export default App;
