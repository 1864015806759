// Github: https://github.com/alchemyplatform/alchemy-sdk-js
// Setup: npm install @alch/alchemy-sdk
import {
  Network,
  initializeAlchemy,
  getNftsForCollection,
} from "@alch/alchemy-sdk";

  // Optional Config object, but defaults to demo api-key and eth-mainnet.
  const settings = {
    apiKey: "_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J", // Replace with your Alchemy API Key.
    network: Network.ETH_MAINNET, // Replace with your network.
    maxRetries: 10,
  };
  
  const alchemy = initializeAlchemy(settings);


  export const getCollection = async() => {

        var forEachData = ''
        var NFTArray = [];

        async function callGetNFTsForCollectionOnce(
          startToken = ""
        ) {
          var mainCollection = await getNftsForCollection(
            alchemy,
            "0x982cd174651725c41292Aa0F751445Ae746266Ea",
            {
              pageKey: startToken,
            }
        );
          return mainCollection;
        }
        
        let startToken = "";
        let hasNextPage = true;
        let totalNftsFound = 0;
        while (hasNextPage) {
          const { nfts, pageKey } = await callGetNFTsForCollectionOnce(
            startToken
          );
          if (!pageKey) {
            // When nextToken is not present, then there are no more NFTs to fetch.
            hasNextPage = false;
          }

          startToken = pageKey;
          totalNftsFound += nfts.length;
          NFTArray = NFTArray.concat(nfts);
        }
      
        NFTArray = NFTArray.reverse();

        NFTArray.forEach(
          async function(d){


            // console.log(d);


      
              forEachData += '<div class="col-md-4 pb-4"><div class="card h-100"><div class="card-body"><h5 class="card-title">' + d.title + '</h5><p class="card-text"><img src="' + d.rawMetadata.image + '" class="img-fluid" /></p></div></div></div>';

           }
        )

        return {
          mainCollection: forEachData,
        }
  }
