import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';

const API_URL = 'https://eth-mainnet.g.alchemy.com/nft/v2/_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J/getNFTMetadata';
const MySwal = withReactContent(Swal)

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const coinABI = require('../abi/coin-abi.json')
const coinAddress = "0x3E375D9d3b9A9FC80D9e8ab39B5a2A72BF29391E";
const mainABI = require('../abi/main-abi.json')
const mainAddress = "0x982cd174651725c41292Aa0F751445Ae746266Ea";

export const beachHutCommissions = async() => {

    window.mainContract = await new web3.eth.Contract(mainABI, mainAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();

    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, mainAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();

    if(approved < maxSupply) {
        approved = "Approve Coin Spending";
    } else {
        approved = "Mint NFT";
    }

    var unlockableNFTs = [16,32,57];
    var counter = 0;
    var unlockableNFT = false;

    for(var i = 0; i < unlockableNFTs.length; i++){
        var ownNFTs = await window.mainContract.methods.balanceOf(window.ethereum.selectedAddress, unlockableNFTs[i]).call();

        if(ownNFTs > 0) {
            counter++;
        }
    }
    
    var unlockableQty = Number(await window.mainContract.methods.unlockableQty().call());

    if(counter >= unlockableQty) {
        unlockableNFT = true;
    }

    return {
      approved: approved,
      unlockableNFT: unlockableNFT,
    }
  }

export const mintNFT = async(id, amount) => {

    window.mainContract = await new web3.eth.Contract(mainABI, mainAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();

    if (id.trim() == "") { 
        MySwal.fire({
            title: <p>Sorry</p>,
            text: 'Please make sure you select your NFT before minting.',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        return {
            success: false,
            status: "❗Please make sure you select your NFT before minting.",
        }
    }

    if (await window.mainContract.methods.paused().call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'Contract is paused.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗Contract is paused.",
        }
      }

      var coinBalance = await window.coinContract.methods.balanceOf(window.ethereum.selectedAddress).call();
      var tokenPrice = await window.mainContract.methods.tokenPrice().call();

      if (Number(coinBalance) < Number(tokenPrice)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You do not own enough Malibu Coins to make this transaction. 3',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You do not own enough Malibu Coins to make this transaction.",
        }
      }

    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, mainAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();
    // maxSupply =  Number(maxSupply);
    
    if((approved) < maxSupply) {
    
    const transactionParameters = {
        to: coinAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.coinContract.methods.approve(mainAddress, maxSupply).encodeABI() //make call to NFT smart contract 
    };
    
        try {
            const txHash = await window.ethereum
                .request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                });
            return {
                success: true,
                status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
            }
        } catch (error) {
            return {
                success: false,
                status: "😥 Something went wrong: " + error.message
            }
        }
    }

    var totalSupply = await window.mainContract.methods.totalSupply(id).call();
    var tokenQty = await window.mainContract.methods.tokenQty().call();

    if (Number(totalSupply) >= Number(tokenQty)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'All Minted.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗All Minted.",
        }
      }

      var balanceOf = await window.mainContract.methods.balanceOf(window.ethereum.selectedAddress, id).call();
      var maxMintQty = await window.mainContract.methods.maxMintQty().call();
      var maxWalletQty = await window.mainContract.methods.maxWalletQty().call();

      maxMintQty = Number(maxMintQty) + Number(balanceOf);

      if (Number(maxMintQty) > Number(maxWalletQty)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You have hit the max tokens per wallet.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You have hit the max tokens per wallet.",
        }
      }

      var currentTokenId = await window.mainContract.methods.currentTokenId().call();

      if (id > currentTokenId) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'This NFT has not been published yet.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗This NFT has not been published yet.",
        }
      }

    const transactionParameters = {
        to: mainAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.mainContract.methods.mint(id).encodeABI() //make call to NFT smart contract 
      };
    
      try {
          const txHash = await window.ethereum
              .request({
                  method: 'eth_sendTransaction',
                  params: [transactionParameters],
              });
          return {
              success: true,
              status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
          }
      } catch (error) {
          return {
              success: false,
              status: "😥 Something went wrong: " + error.message
          }
      }
}

export const mintUnlockable = async() => {

    window.mainContract = await new web3.eth.Contract(mainABI, mainAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();

    if (await window.mainContract.methods.paused().call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'Contract is paused.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗Contract is paused.",
        }
      }

    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, mainAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();
    // maxSupply =  Number(maxSupply);
    
    if((approved) < maxSupply) {
    
    const transactionParameters = {
        to: coinAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.coinContract.methods.approve(mainAddress, maxSupply).encodeABI() //make call to NFT smart contract 
    };
    
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
    }

    var coinBalance = await window.coinContract.methods.balanceOf(window.ethereum.selectedAddress).call();
    var unlockablePrice = await window.mainContract.methods.unlockablePrice().call();

    if (Number(coinBalance) < Number(unlockablePrice)) {
      MySwal.fire({
        title: <p>Sorry</p>,
        text: 'You do not own enough Malibu Coins to make this transaction. 4',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      return {
          success: false,
          status: "❗You do not own enough Malibu Coins to make this transaction.",
      }
    }

    var currentUnlockableId = await window.mainContract.methods.currentUnlockableId().call();
    var totalSupply = await window.mainContract.methods.totalSupply(currentUnlockableId).call();
    var tokenQty = await window.mainContract.methods.tokenQty().call();

    if (Number(totalSupply) >= Number(tokenQty)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'All Minted.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗All Minted.",
        }
      }

      var balanceOf = await window.mainContract.methods.balanceOf(window.ethereum.selectedAddress, currentUnlockableId).call();
      var maxMintQty = await window.mainContract.methods.maxMintQty().call();
      var maxWalletQty = await window.mainContract.methods.maxWalletQty().call();

      maxMintQty = Number(maxMintQty) + Number(balanceOf);

      if (Number(maxMintQty) > Number(maxWalletQty)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You have already claimed this unlockable.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You have already claimed this unlockable.",
        }
      }

    const transactionParameters = {
        to: mainAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.mainContract.methods.mintUnlockable().encodeABI() //make call to NFT smart contract 
      };
    
      try {
          const txHash = await window.ethereum
              .request({
                  method: 'eth_sendTransaction',
                  params: [transactionParameters],
              });
          return {
              success: true,
              status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
          }
      } catch (error) {
          return {
              success: false,
              status: "😥 Something went wrong: " + error.message
          }
      }
}

async function getNFTMetadata(contractAddress, tokenId) {
    const url = `${API_URL}?contractAddress=${contractAddress}&tokenId=${tokenId}&refreshCache=false`;
    const response = await axios.get(url, {
        headers: {
            accept: 'application/json'
        }
    });
    return response.data;
}


export const collectQuantities = async(book1qty) => {

    window.mainContract = await new web3.eth.Contract(mainABI, mainAddress);
    
    var tokenQty = await window.mainContract.methods.tokenQty().call();
    var totalTokens = await window.mainContract.methods.currentTokenId().call();
    var backfillId = await window.mainContract.methods.backfillTokenId().call();
    var tokenQuantities = [];
    var metaData = "";

    for (var i = backfillId; i <= totalTokens; i++) {
        var supply = await window.mainContract.methods.totalSupply(i).call();
        if (tokenQty - supply > 0) {

            await getNFTMetadata(mainAddress, i).then(data => {
                metaData = data.metadata;
            });

            tokenQuantities.push({
                id: i,
                quantity: tokenQty - supply,
                name: metaData.name,
                image: metaData.image,
            });
        }
    }

    tokenQuantities.reverse();

    var currentUnlockableId = await window.mainContract.methods.currentUnlockableId().call();
    var unlockable = tokenQty - await window.mainContract.methods.totalSupply(currentUnlockableId).call();

    return {
        unlockable: unlockable,
        tokenQuantities: tokenQuantities,
    }
}
