import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/_TyLuYBWw6Jp6z4tX7mxuZWKFE1VwX3J";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const coinABI = require('../abi/coin-abi.json')
const coinAddress = "0x3E375D9d3b9A9FC80D9e8ab39B5a2A72BF29391E";
const passesABI = require('../abi/passes-abi.json')
const passesAddress = "0x5B144e951022032B27910E0c40666b9bF1d4e01c";

const MySwal = withReactContent(Swal)

export const beachHutPasses = async() => {

    window.passesContract = await new web3.eth.Contract(passesABI, passesAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();
  
    var commissionQty = await window.passesContract.methods.commissionQty().call();
    var privateQty = await window.passesContract.methods.privateQty().call();
    var commissionSupply = await window.passesContract.methods.totalSupply(1).call();
    var privateSupply = await window.passesContract.methods.totalSupply(2).call();
  
    var commissionOwned = await window.passesContract.methods.balanceOf(window.ethereum.selectedAddress, 1).call();
    var privateOwned = await window.passesContract.methods.balanceOf(window.ethereum.selectedAddress, 2).call();
  
    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, passesAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();

    if(approved < maxSupply) {
        approved = "Approve Coin Spending";
    } else {
        approved = "Mint Pass";
    }

    return {
      commissionQty: commissionQty,
      privateQty: privateQty,
      commissionSupply: commissionSupply,
      privateSupply: privateSupply,
      commissionOwned: commissionOwned,
      privateOwned: privateOwned,
      approved: approved,
    }
  }
  
  export const mintCommissionPass = async() => {

    window.passesContract = await new web3.eth.Contract(passesABI, passesAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();

    if (await window.passesContract.methods.paused().call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'Contract is paused.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗Contract is paused.",
        }
      }

      var coinBalance = await window.coinContract.methods.balanceOf(window.ethereum.selectedAddress).call();
      var commissionPrice = await window.passesContract.methods.commissionPrice().call();

      if (Number(coinBalance) < Number(commissionPrice)) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You do not own enough Malibu Coins to make this transaction. 1',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You do not own enough Malibu Coins to make this transaction.",
        }
      }
  
    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, passesAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();
    // TRY - increaseAllowance instead of approve
  
    if(approved < maxSupply) {
     
      const transactionParameters = {
        to: coinAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.coinContract.methods.approve(passesAddress, maxSupply).encodeABI() //make call to NFT smart contract 
      };
    
      try {
          const txHash = await window.ethereum
              .request({
                  method: 'eth_sendTransaction',
                  params: [transactionParameters],
              });
          return {
              success: true,
              status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
          }
      } catch (error) {
          return {
              success: false,
              status: "😥 Something went wrong: " + error.message
          }
      }
    }
  
    const transactionParameters = {
      to: passesAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, 
      'data': window.passesContract.methods.mintCommissionPass().encodeABI() //make call to NFT smart contract 
    };
  
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
  }
 
  export const mintPrivatePass = async() => {
  
    window.passesContract = await new web3.eth.Contract(passesABI, passesAddress);//loadContract();
    window.coinContract = await new web3.eth.Contract(coinABI, coinAddress);//loadContract();

    if (await window.passesContract.methods.paused().call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'Contract is paused.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗Contract is paused.",
        }
      }

      var coinBalance = await window.coinContract.methods.balanceOf(window.ethereum.selectedAddress).call();
      var privatePrice = await window.passesContract.methods.privatePrice().call();

      if (coinBalance < privatePrice) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You do not own enough Malibu Coins to make this transaction. 2',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You do not own enough Malibu Coins to make this transaction.",
        }
      }
  
    var approved = await window.coinContract.methods.allowance(window.ethereum.selectedAddress, passesAddress).call();
    var maxSupply = await window.coinContract.methods.maxSupply().call();
  
    if(approved < maxSupply) {
     
      const transactionParameters = {
        to: coinAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, 
        'data': window.coinContract.methods.approve(passesAddress, maxSupply).encodeABI() //make call to NFT smart contract 
      };
    
      try {
          const txHash = await window.ethereum
              .request({
                  method: 'eth_sendTransaction',
                  params: [transactionParameters],
              });
          return {
              success: true,
              status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
          }
      } catch (error) {
          return {
              success: false,
              status: "😥 Something went wrong: " + error.message
          }
      }
    }
  
    const transactionParameters = {
      to: passesAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, 
      'data': window.passesContract.methods.mintPrivatePass().encodeABI() //make call to NFT smart contract 
    };
  
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
  }

  export const redeemCommissionPass = async() => {
  
    window.passesContract = await new web3.eth.Contract(passesABI, passesAddress);//loadContract();

      if (await window.passesContract.methods.balanceOf(window.ethereum.selectedAddress, 1).call() == 0) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You do not own a commission pass.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You do not own a commission pass.",
        }
      }

      if (await window.passesContract.methods.isCommissionQueue(window.ethereum.selectedAddress).call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You already have a commission pass in the queue.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You already have a commission pass in the queue.",
        }
      }
  
    const transactionParameters = {
      to: passesAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, 
      'data': window.passesContract.methods.redeemCommissionPass().encodeABI() //make call to NFT smart contract 
    };
  
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
  }

  export const redeemPrivatePass = async() => {
  
    window.passesContract = await new web3.eth.Contract(passesABI, passesAddress);//loadContract();

      if (await window.passesContract.methods.balanceOf(window.ethereum.selectedAddress, 2).call() == 0) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You do not own a private pass.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You do not own a private pass.",
        }
      }

      if (await window.passesContract.methods.isPrivateQueue(window.ethereum.selectedAddress).call()) {
        MySwal.fire({
          title: <p>Sorry</p>,
          text: 'You already have a private pass in the queue.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return {
            success: false,
            status: "❗You already have a private pass in the queue.",
        }
      }
  
    const transactionParameters = {
      to: passesAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, 
      'data': window.passesContract.methods.redeemPrivatePass().encodeABI() //make call to NFT smart contract 
    };
  
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: <https://ropsten.etherscan.io/tx/>" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }
  }
