import parse from 'html-react-parser'

import { connectWallet, getCurrentWalletConnected, getBalances } from "./utils/interact.js";
import { getOwnedMBH } from "./utils/alchemy-owned-mbh.js";
import { useEffect, useState } from "react";


const UserCollection = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [NFTCollection, setNFTCollection] = useState("");
  const [collectorScore, setCollectorScore] = useState("");

 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);

    addWalletListener();

    if(address) {
      const { collected, collectorScore } = await getOwnedMBH(address);
      setNFTCollection(collected);
      setCollectorScore(collectorScore);
    }
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    
    if(walletResponse.address) {
      const { collected, collectorScore } = await getOwnedMBH(walletResponse.address);
      setNFTCollection(collected);
      setCollectorScore(collectorScore);
    }

  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the button above.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
      <div className="Commissions">
            <div class="jumbotron bg-transparent mb-0 radius-0">
                  <div class="container">
                      <div class="row">
                            <div class="col-xl-12">
                                <h1 class="display-2">Malibu Beach Hut 3.0</h1>
                                <div class="lead mb-3 text-mono text-success">Your Beach Hut collection &amp; collectors score<span class="vim-caret">_</span></div>
                            </div>
                      </div>
                  </div>
                  <div class="container py-5 mb5">

                    <div class="row pt-4 bg-success rounded">


                        <div class="col-6">

                                {/* {walletAddress.length > 0 && */}
                                <div class="text-mono mb-4">
                            
                                    <a href="#!"
                                        class="btn btn-danger btn-shadow px-3 my-2 ml-0 ml-sm-1 text-left" id="walletButton" onClick={connectWalletPressed}>
                                        {walletAddress.length > 0 ? (
                                        "Connected: " +
                                        String(walletAddress).substring(0, 6) +
                                        "..." +
                                        String(walletAddress).substring(38)
                                        ) : (
                                        <span>Connect Wallet</span>
                                        )}
                                    </a>
                                {/* }   */}
                                </div>
                        </div>

                        <div class="col-6 text-right pt-3">
                            {walletAddress.length > 0 &&            
                                <h4 class="mb-3">
                                    <span class="text-white pr-3">Your Collector Score</span>
                                    <span class="badge badge-dark badge-pill">{collectorScore}</span>
                                </h4>
                            }
                        </div>

                      </div>


                      {walletAddress.length > 0 &&            
                            <div class="row py-4">

                                { parse(NFTCollection) }

                            </div>
                        }

                  </div>
            </div>
      </div>
  );
};

export default UserCollection;
