import { connectWallet, getCurrentWalletConnected, mintMembershipNFT, getBalances, claimCoins } from "./utils/interact.js";
import { beachHutPasses, mintCommissionPass, mintPrivatePass, redeemCommissionPass, redeemPrivatePass } from "./utils/beach-hut-passes.js";
import { useEffect, useState } from "react";
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

// const MySwal = withReactContent(Swal)

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [coinsOwned, setCoinsOwned] = useState("");
  const [coinsUnclaimed, setCoinsUnclaimed] = useState("");
  const [membershipsOwned, setMembershipsOwned] = useState("");
  const [genesisOwned, setGenesisOwned] = useState("");
  const [remainingSupply1, setRemainingSupply1] = useState("");
  const [commissionQty, setCommissionQty] = useState("");
  const [privateQty, setPrivateQty] = useState("");
  const [commissionSupply, setCommissionSupply] = useState("");
  const [privateSupply, setPrivateSupply] = useState("");
  const [commissionOwned, setCommissionOwned] = useState("");
  const [privateOwned, setPrivateOwned] = useState("");
  const [approved, setApproved] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");

  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);

    addWalletListener();

    if(address) {
      const { coinsOwned, coinsUnclaimed, membershipsOwned, genesisOwned, remainingSupply1, tokenPrice} = await getBalances();

      setCoinsOwned(coinsOwned);
      setCoinsUnclaimed(coinsUnclaimed);
      setMembershipsOwned(membershipsOwned);
      setGenesisOwned(genesisOwned);
      setRemainingSupply1(remainingSupply1);
      setTokenPrice(tokenPrice);

      const { commissionQty, privateQty, commissionSupply, privateSupply, commissionOwned, privateOwned, approved} = await beachHutPasses();

      setCommissionQty(commissionQty);
      setPrivateQty(privateQty);
      setCommissionSupply(commissionSupply);
      setPrivateSupply(privateSupply);
      setCommissionOwned(commissionOwned);
      setPrivateOwned(privateOwned);
      setApproved(approved);
    }

  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    

    if(walletResponse.address) {
      const { coinsOwned, coinsUnclaimed, membershipsOwned, genesisOwned, remainingSupply1, tokenPrice} = await getBalances();

      setCoinsOwned(coinsOwned);
      setCoinsUnclaimed(coinsUnclaimed);
      setMembershipsOwned(membershipsOwned);
      setGenesisOwned(genesisOwned);
      setRemainingSupply1(remainingSupply1);
      setTokenPrice(tokenPrice);

      const { commissionQty, privateQty, commissionSupply, privateSupply, commissionOwned, privateOwned, approved} = await beachHutPasses();

      setCommissionQty(commissionQty);
      setPrivateQty(privateQty);
      setCommissionSupply(commissionSupply);
      setPrivateSupply(privateSupply);
      setCommissionOwned(commissionOwned);
      setPrivateOwned(privateOwned);
      setApproved(approved);
    }

  };

  const onMintPressed = async () => { //TODO: implement
    const { status } = await mintMembershipNFT();
    setStatus(status);
  };


  const onClaimPressed = async () => { //TODO: implement
    const { status } = await claimCoins();
    setStatus(status);
  };


  const onCommissionPressed = async () => { //TODO: implement
    const { status } = await mintCommissionPass();
    // setStatus(status);
  };

  const onPrivatePressed = async () => { //TODO: implement
    const { status } = await mintPrivatePass();
    setStatus(status);
  };

  const onRedeemCommissionPress = async () => { //TODO: implement
    const { status } = await redeemCommissionPass();
    setStatus(status);
  };

  const onRedeemPrivatePress = async () => { //TODO: implement
    const { status } = await redeemPrivatePass();
    setStatus(status);
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the button above.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }


return (
    <div className="Minter">


<div class="jumbotron bg-transparent mb-0 radius-0">

  <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <h1 class="display-2">Malibu Beach Hut 3.0</h1>
          <div class="lead mb-3 text-mono text-success">Mint membership NFTs, view &amp; claim your Malibu Coins<span class="vim-caret">_</span></div>

        </div>
      </div>
  </div>


  <div class="container py-5 mb5">

      <div class="row py-4">



      {walletAddress.length > 0 &&
        <div class="col-md-4 order-md-2 mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span class="text-muted">Your Malibu Coins</span>
              <span class="badge badge-success badge-pill">{coinsOwned}</span>
            </h4>
            <ul class="list-group mb-3">
              <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Unclaimed Coins</h6>
                  <small class="text-muted">Coins accumulated</small>
                </div>
                <span class="text-muted">{coinsUnclaimed}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Membership NFTs</h6>
                  <small class="text-muted">Your memeberships</small>
                </div>
                <span class="text-muted">{membershipsOwned} ({genesisOwned} Genesis)</span>
              </li>
              <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Commission Passes</h6>
                  <small class="text-muted">Your Beach Hut Passes</small>
                </div>
                <span class="text-muted">{commissionOwned}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Private Passes</h6>
                  <small class="text-muted">Your Beach Hut Passes</small>
                </div>
                <span class="text-muted">{privateOwned}</span>
              </li>
            </ul>

            {Number(commissionOwned) > 0 &&
                <div class="input-group">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left text-mono" id="redeemCommission" onClick={onRedeemCommissionPress}>Redeem 1 Commission Pass</button>
                  </div>
                </div>
            }

            {Number(privateOwned) > 0 &&
                <div class="input-group">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left text-mono" id="redeemPrivate" onClick={onRedeemPrivatePress}>Redeem 1 Private Pass</button>
                  </div>
                </div>
            }

            {Number(coinsUnclaimed) > 0 &&
              <div class="input-group">
                {/* <input type="text" class="form-control" placeholder="Promo code"> */}
                <div class="input-group-append">
                  <button type="submit" class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left text-mono" id="claimButton" onClick={onClaimPressed}>Claim Malibu Coins</button>
                </div>
              </div>
            }

                <a href="#!"
                  class="btn btn-danger btn-shadow px-3 my-2 ml-0 ml-sm-1 text-left text-mono" id="walletButton" onClick={connectWalletPressed}>
                  {walletAddress.length > 0 ? (
                    "Connected: " +
                    String(walletAddress).substring(0, 6) +
                    "..." +
                    String(walletAddress).substring(38)
                  ) : (
                    <span>Connect Wallet</span>
                  )}
                </a>
          </div>
        }  





          <div class="col-md-8 order-md-1">


          {walletAddress.length > 0 &&
              <h4 class="mb-3">Malibu Beach Hut Membership NFTs</h4>
          }

          {walletAddress.length > 0 &&
          <div class="row">
              <div class="col-md-6">
                  <div class="card h-100">
                      <div class="card-body">
                      <h5 class="card-title">Genesis Membership</h5>
                          <p class="card-text">
                            <img src="images/Comp 3.gif" class="img-fluid" />
                            <br /><div class="bg-primary text-white py-2">&nbsp;&nbsp;{remainingSupply1}/50 Remaining <span class="float-right pr-2">{tokenPrice} ETH</span></div>
                          </p>
                      </div>
                      <div class="card-footer text-mono pt-3">
                        {walletAddress.length > 0 && (


                          <>
                          {Number(remainingSupply1) == Number(0) ? (
                            <a  href="#!"
                                class="btn btn-danger btn-shadow px-3 my-2 ml-0 text-left">
                                SOLD OUT
                            </a>
                          ) : (
                            <a  href="#!"
                                class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left" id="mintButton" onClick={onMintPressed}>
                                Mint Membership NFT
                            </a>
                          )}
                          </>


                        )}
                      </div>
                      {/* {book1qty > 0
                        ?  <div class="card-footer inputGroup">
                            <input id="radio1" value="1" name="book" type="radio"/>
                            <label for="radio1">Choose Book #1</label>
                          </div>
                        :  <div class="card-footer inputGroup">
                            <input id="radio1" value="0" name="book" type="radio" disabled  />
                            <label for="radio1">Sold Out!</label>
                          </div>
                      } */}
                  </div>


              </div>

              <div class="col-md-6">
                  <div class="card h-100">
                      <div class="card-body">
                      <h5 class="card-title">Standard Membership</h5>
                          <p class="card-text">
                            <img src="images/Comp 2_3.gif" class="img-fluid" />
                            <br /><div class="bg-primary text-white py-2">&nbsp;&nbsp;Not Currently Available</div>
                          </p>
                      </div>



                  </div>
                </div>

            </div>
            }






            <div class="text-mono">
              {walletAddress.length > 0 ? (
                <div></div>
                // <a href="#!"
                //   class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left" id="mintButton" onClick={onMintPressed}>
                //   Mint Membership NFT
                // </a>
               ) : (

                <a href="#!"
                class="btn btn-danger btn-shadow px-3 my-2 ml-0 ml-sm-1 text-left" id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </a>
              )}


            </div>


            {/* <div class="text-darkgrey text-mono my-2">
                <p class="mt-5" id="status">
                  {status}
                </p>
            </div> */}


          </div>

        </div>

        <div class="row pt-5">

<div class="col-md-8 order-md-1">


  {walletAddress.length > 0 &&
      <h4 class="mb-3">Malibu Beach Hut Passes</h4>
  }

  {walletAddress.length > 0 &&
  <div class="row">
      <div class="col-md-6">
          <div class="card h-100">
              <div class="card-body">
              <h5 class="card-title">Commission Pass</h5>
                  <p class="card-text">
                    <img src="images/JAMES1.png" class="img-fluid" />
                    <br /><div class="bg-primary text-white py-2">&nbsp;&nbsp;{commissionQty-commissionSupply}/{commissionQty} Remaining <span class="float-right pr-2">1,200 KMC</span></div>
                    {/* ???/??? Remaining</div> */}
                  </p>
              </div>
              <div class="card-footer text-mono pt-3">
                {walletAddress.length > 0 && (


                  <>
                  {Number(commissionQty-commissionSupply) == Number(0) ? (
                    <a  href="#!"
                        class="btn btn-danger btn-shadow px-3 my-2 ml-0 text-left">
                        SOLD OUT
                    </a>
                  ) : (
                    <a  href="#!"
                        class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left" id="commissionButton" onClick={onCommissionPressed}>
                        {approved}
                    </a>
                  )}
                  </>


                )}
              </div>
          </div>


      </div>

      <div class="col-md-6">
          <div class="card h-100">
              <div class="card-body">
              <h5 class="card-title">Private Pass</h5>
                  <p class="card-text">
                    <img src="images/JAMES2.png" class="img-fluid" />
                    <br /><div class="bg-primary text-white py-2">&nbsp;&nbsp;{privateQty-privateSupply}/{privateQty} Remaining <span class="float-right pr-2">1,200 KMC</span></div>
                  </p>
              </div>
              <div class="card-footer text-mono pt-3">
                {walletAddress.length > 0 && (


                  <>
                  {Number(privateQty-privateSupply) == Number(0) ? (
                    <a  href="#!"
                        class="btn btn-danger btn-shadow px-3 my-2 ml-0 text-left">
                        SOLD OUT
                    </a>
                  ) : (
                    <a  href="#!"
                        class="btn btn-success btn-shadow px-3 my-2 ml-0 text-left" id="privateButton" onClick={onPrivatePressed}>
                        {approved}
                    </a>
                  )}
                  </>


                )}
              </div>



          </div>
        </div>

    </div>
    }
    


    <div class="text-darkgrey text-mono my-2">
        {/* <p class="mt-5" id="status">
          {status}
        </p> */}
    </div>


  </div>

</div>
        
    </div>

</div>






{/* <div class="container py-5">
  <h1>Thanks</h1>

  <p>Thank you for downloading this theme. If you have trouble or find a bug, please open an issue on GitHub:<br />
    <a href="https://github.com/HackerThemes/theme-machine">https://github.com/HackerThemes/theme-machine</a></p>
</div> */}




{/* 
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button> */}

      {/* <br></br>
      <h1 id="title">🧙‍♂️ Alchemy NFT Minter</h1>
      <p>
        Simply add your asset's link, name, and description, then press "Mint."
      </p>
      <form>
        <h2>🖼 Link to asset: </h2>
        <input
          type="text"
          placeholder="e.g. https://gateway.pinata.cloud/ipfs/<hash>"
          onChange={(event) => setURL(event.target.value)}
        />
        <h2>🤔 Name: </h2>
        <input
          type="text"
          placeholder="e.g. My first NFT!"
          onChange={(event) => setName(event.target.value)}
        />
        <h2>✍️ Description: </h2>
        <input
          type="text"
          placeholder="e.g. Even cooler than cryptokitties ;)"
          onChange={(event) => setDescription(event.target.value)}
        />
      </form> */}
      {/* <button id="mintButton" onClick={onMintPressed}>
        Mint NFT
      </button> */}
      {/* <p id="status">
        {status}
      </p> */}
    </div>
  );
};

export default Minter;
